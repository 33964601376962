@import '../styles/_colors.less';

.ag-root-wrapper {
    border-radius: 3px;

    .ag-header {
        background-color: @primary-color;
        
        .ag-header-cell-label {
            color: @white;
            font-size: 15px;
            justify-content: center;
        }
        
        .ag-header-icon {
            color: @white;
        }
    }

    .ag-body-viewport {
        .ag-row-even, .ag-row-odd {
            cursor: pointer;

            .ag-cell {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
            }
        }
    }
}
.ag-theme-alpine .ag-checkbox-input-wrapper.ag-checked::after {
    color: @primary-color;
}
.ag-theme-alpine .ag-row-selected {
    background-color: @primary-color-transparent;
}
.ag-theme-alpine .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected) {
    border-color: transparent;
}