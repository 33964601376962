@import './_colors.less';


// button
@btn-primary-bg: @primary-color;

// Menu
@menu-dark-item-active-bg: fade(@white; 75%);
@menu-dark-color: @white;
@menu-dark-selected-item-icon-color: @black;
@menu-dark-selected-item-text-color: @black;

// Tag
@tag-line-height: 30px;
@tag-font-size: 16px;

// Card
@card-head-font-size: 20px;

// Collapse
.ant-collapse-header {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
}